import { AppBar, Box, Button, Slider, Tab, Tabs, Typography, withWidth } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';
import Header from '../../components/header';
import Point from '../../components/tsp/Point';
import PathInfo from '../../components/tsp/PathInformation';

// @ts-ignore
import SolverWorker from 'comlink-loader!../../components/tsp/Solver.worker';
import * as Comlink from 'comlink';

import './index.css';

const POINT_COLOR: string = '#000000';

let points: Point[] = [];
let pathToShow: PathInfo;

function resizeCanvasToDisplaySize(canvas) {
    // look up the size the canvas is being displayed
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
 
    // If it's resolution does not match change it
    if (canvas.width !== width || canvas.height !== height) {
      canvas.width = width;
      canvas.height = height;
      return true;
    }
 
    return false;
 }

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
}

class TSP extends React.Component {}

function Page() {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    let [isRunning, setIsRunning] = useState(false);
    const [distance, setDistance] = useState(0);
    const [num_points, setNumPoints] = useState(10);
    const [time_limit, setTimeLimit] = useState(1);

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let solverWorker = new SolverWorker();

    useEffect(() => {
        console.log(canvasRef)
        console.log(canvasRef.current.clientHeight);
        console.log(canvasRef.current.height);
        resizeCanvasToDisplaySize(canvasRef.current);
        refreshWithNewPoints();
    }, []);

    function generateNewPoints() {
        const canvas = canvasRef.current;
        let width = canvas.width;
        let height = canvas.height;

        let path = [];
        let newPoints = [];
        for (let i = 0; i < num_points; i++) {
            let x = Math.floor(Math.random() * (width - 50)) + 25;
            let y = Math.floor(Math.random() * (height - 50)) + 25;
            newPoints.push(new Point(x, y));
            path.push(i);
        }
        points = newPoints;
        pathToShow = new PathInfo(0, []);
    }

    function clearCanvas() {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        //Our first draw
        context.fillStyle = '#ffffff'
        context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    }

    function drawPoints() {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.fillStyle = POINT_COLOR;

        const point_radius = canvas.height * .02

        points.forEach((point: Point) => {    
            context.beginPath();
            context.arc(point.x, point.y, point_radius, 0, 2 * Math.PI);
            context.fill();
        })
    }

    function drawSolution(pathInfo: PathInfo, color: string) {
        const path = pathInfo.path;
        if (path.length == 0) return;

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.strokeStyle = color;

        let point = points[path[0]]
        context.moveTo(point.x, point.y);
        context.beginPath();
        for (let i = 0; i < num_points; i++) {
            let p = points[path[i]];
            context.lineTo(p.x, p.y);    
        }
        context.lineTo(point.x, point.y);
        context.stroke();
    }

    function refreshCanvas() {
        clearCanvas();
        drawPoints();
        drawSolution(pathToShow, 'red');
    }

    function refreshWithNewPoints() {
        generateNewPoints();
        refreshCanvas();
    }

    function solveGreedy() {
        setIsRunning(true);
        solverWorker.solveWithGreedy(points).then((dat) => {
            pathToShow = dat;
            setDistance(pathToShow.distance);
            setIsRunning(false);
            refreshCanvas();
        });
    }

    function solveMCTS() {
        setIsRunning(true);
        solverWorker.solveWithMCTS(points, 1000 * time_limit, Comlink.proxy((dat) => {
            pathToShow = dat;
            setDistance(pathToShow.distance);
            refreshCanvas();
        })).then(() => {
            setIsRunning(false);
        })
    }

    function solveWithAntColony() {
        setIsRunning(true);
        solverWorker.solveWithAntColony(points, 1000 * time_limit, Comlink.proxy((dat) => {
            pathToShow = dat;
            setDistance(pathToShow.distance);
            refreshCanvas();
        })).then(() => {
            setIsRunning(false);
        })
    }

    function solveWithSimulatedAnnealing() {
        setIsRunning(true);
        solverWorker.solveWithAnnealing(points, 1000 * time_limit, Comlink.proxy((dat) => {
            pathToShow = dat;
            setDistance(pathToShow.distance)
            refreshCanvas();
        })).then((dat) => {
            pathToShow = dat;
            setDistance(pathToShow.distance)
            refreshCanvas();
            setIsRunning(false);
        })
    }

    return (
        <Header>
            <div style={{textAlign:'center'}}>
                <Typography variant='h2' style={{textAlign:'center'}}>
                    Travelling Sales Person
                </Typography>
                <div style={{marginBottom: '10px'}}>
                    <canvas style={{maxWidth: '800px', maxHeight: '500px', width:'100%', height: '100%'}} ref={canvasRef}/>
                </div>
                <div>
                    <div style={{marginBottom: '10px'}}>
                        Distance of Path Shown: {distance == 0 ? "Run an algorithm to see a path value" : distance.toFixed(2)}
                    </div>
                    <div style={{marginBottom: '10px'}}>
                    </div>
                </div>

                <div style={{display:'inline-block', maxWidth:'800px', marginLeft: '10px', marginRight: '10px'}}>
                    <AppBar position="static" style={{borderRadius:'10px'}}>
                        <Tabs value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="The Problem" />
                            <Tab label="Greedy" />
                            <Tab label="MCTS" />
                            <Tab label="Ant Colony" />
                            <Tab label="Simulated Annealing" />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Typography variant='h4'>What is the Travelling Salesperson Problem?</Typography>
                        <div style={{marginBottom:'10px'}}>
                            The TLDR is how short is the shortest path to visit all defined points.
                        </div>
                        <div style={{marginBottom:'10px'}}>
                            The slightly longer answer is that the traveling salesperson problem is NP-Hard. This means that the time to find the optimal solution can take a very very long time. This has led some very smart people to try and find way to find "good enough" solutions to the problem. I have implemented a few of those on this page. Click around on the other tabs to see them in action!
                        </div>
                        <div>
                            <Button variant="contained" color="secondary" disabled={isRunning} onClick={() => refreshWithNewPoints()}>
                                Generate New Points
                            </Button>
                            <Slider min={3} max={50} step={1} value={num_points} onChange={(_, v: any) => {setNumPoints(v);}} valueLabelDisplay="auto" aria-labelledby="continuous-slider_1" />
                            
                            <div style={{width:'300px', textAlign:'center', display: 'inline-block'}}>
                                <div style={{display:'flex'}}>
                                    <Typography id="discrete-slider">
                                        Time limit for algorithms
                                    </Typography>
                                    <Slider style={{maxWidth: '300px', marginLeft: '20px'}} min={1} max={10} step={1} value={time_limit} onChange={(_, v: any) => {setTimeLimit(v);}} valueLabelDisplay="auto" aria-labelledby="continuous-slider" />
                                </div>
                            </div>
                            
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Button variant="contained" color="secondary" style={{marginBottom: '10px'}} disabled={isRunning} onClick={() => solveGreedy()}>
                            See it in action
                        </Button>
                        <Typography style={{marginBottom:'10px'}} variant='h4'>How does the greedy solution work?</Typography>
                        <div style={{marginBottom:'10px'}}>
                            This works based on given some starting node, the salesperson will go to the closest not visited node. This generally works pretty well but, sometimes it generates some pretty non optimal results. It should also be noted that this is a deterministic algorithm as it will always generate the same path given the same set of points.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Button variant="contained" color="secondary" style={{marginBottom: '10px'}}  disabled={isRunning} onClick={() => solveMCTS()}>
                            See MCTS in action!
                        </Button>
                        <Typography style={{marginBottom:'10px'}} variant='h4'>How does MCTS work?</Typography>
                        <div style={{marginBottom:'10px'}}>
                            If you've played my connect 4 game on this site, you're already interacted with this algorithm. The idea is that it will do an intelligent search of different paths. It does this by simulating different paths that could be chosen. After it simulates a path, it adds an inverted distance to the nodes it has visited. The reason it is inverted is because we want to value shorter paths over longer paths. The next time a new path is simulated, it will prefer to search along routes that already have a short path. This algorithm is non deterministic so, if you run it a few times, you will probably see a different result each time. 
                        </div>                        
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Button variant="contained" color="secondary" style={{marginBottom: '10px'}}  disabled={isRunning} onClick={() => solveWithAntColony()}>
                            See the Ant Colony in action!
                        </Button>
                        <Typography style={{marginBottom:'10px'}} variant='h4'>How does Ant Colony Optimization solution work?</Typography>
                        <div style={{marginBottom:'10px'}}>
                            This works based on the same idea as how ants find food. Ants find their food and when they find food, they leave some pheremons that other ants can follow to the same source of food. These pheremons evaporate over time leading to shorter paths having stronger pheremons. Ants will prefer to travel on a more "pheremony" path laying down more pheremons. As more and more ants take the same path, it will lead more ants to search down that path.
                        </div>
                        <div style={{marginBottom:'10px'}}>
                            In my solution, the pheremons to between each node on the path is determined by on the total length of the ant's path. The shorter the path the better it's that path is valued and more pheremone will be added to the nodes that that ant has traveled. An ant that has taken a longer path will lay down less pheremon. After each round of sending ants out, I multiply each pheremon value between nodes by .95 to represent the pheremon evaporating.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Button variant="contained" color="secondary" style={{marginBottom: '10px'}}  disabled={isRunning} onClick={() => solveWithSimulatedAnnealing()}>
                            See Simulated Annealing In Action!
                        </Button>
                        <Typography style={{marginBottom:'10px'}} variant='h4'>How does Simulated Annealing work?</Typography>
                        <div style={{marginBottom:'10px'}}>
                            This algorithm works based off the same principles as annealing. At the beginning of the process, it does an explorative search to see if it can find local maximums. Later on, it does more of an explotive search seeing if it can maximize the places it's found.
                        </div>
                        <div style={{marginBottom:'10px'}}>
                            There are a few different things needed to do this algorithm. The first is a cost function. This is pretty simple, we can just use the distance of a path. The second is the neighbor function. This is a little more complicated but, I decided that always swapping at least 2 cities. After two cities are swapped, it then flips a coin to decide if it should swap another pair of cities. It does this until it gets a tails. I found that this combination seems to be pretty good at finding a decent path.
                        </div>
                    </TabPanel>
                </div>
            </div>
        </Header>
    )
}

export default withWidth()(Page);